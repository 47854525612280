<template>
       <div class="form-data secret">
           <div class="title">비밀글</div>
           <div class="value ">
               <input type="checkbox" id="agree" :value="true" v-model="hasSecret" @change="changeSecretSelect(hasSecret)"></input>
               <label for="agree">비밀글</label>
               <span class="noti">(비밀글 설정시, 모든 멘토와 나만 확인이 가능합니다)</span>
           </div>
       </div>
</template>

<script>
    import EventBus from "@/utils/event-bus";
    import BoardValidator from "@/mixins/validators/BoardValidator";

    export default {
        name: "BoardWriteSecretLayout",
        mixins: [BoardValidator],
        // inject: ['boardWriteSetData', 'boardParsingDetailData'],
        inject: {
            boardWriteSetData: { default: () => {} },
            boardParsingDetailData: { default: () => {} }
        },
        props: {
            boardData: {
                default: {}
            },
        },
        data() {
            return {
                hasSecret: false,
            }
        },
        computed: {},
        mounted() {
            if(this.boardData.hasOwnProperty('secret') && this.boardData.secret != null) {
                this.hasSecret = true;
                this.secret = 1;
                this.parentSetData('secret', this.secret);
            }
        },
        methods: {
            changeSecretSelect(check) {
                if(!check) {
                    this.boardWriteSetData('secret', 0);
                }else{
                    this.boardWriteSetData('secret', 1);
                }
            },
            parentSetData(type, value) {
                this.boardWriteSetData(type, value);
            },
        },
    }
</script>

<style scoped>

</style>